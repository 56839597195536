<script setup lang="ts">
const { faq } = useHome()
const localePath = useLocalePath()
</script>

<template>
  <Container v-if="faq" class="screen-content flex flex-col items-start px-0 md:flex-row md:space-x-8">
    <div class="w-full px-8 py-8 md:w-1/2 md:pl-8 lg:w-1/3 lg:pl-16">
      <Heading as="h2" size="caption" class="mb-2">{{ faq.title }}</Heading>
      <Heading as="h3" size="medium" class="mb-4">{{ faq.headline }}</Heading>
      <Paragraph size="medium">
        {{ faq.body }}
      </Paragraph>
    </div>

    <div class="relative h-full w-full px-8 md:w-1/2 md:pl-8 lg:w-2/3 lg:pl-0 lg:pr-16 lg:pt-3">
      <Accordion :items="faq.questions" :disabled-after="3" />
      <div v-if="faq.action" class="action-container absolute bottom-0 left-0 -ml-1 h-32 px-8 md:pl-8 lg:pl-0 lg:pr-16">
        <div class="bg-gradient-white flex h-full w-full items-center justify-center backdrop-blur-[2px]">
          <Action :intent="faq.action.intent" :size="faq.action.size" as="link" :to="localePath('/contact') + '?faq'">
            {{ faq.action.label }}
          </Action>
        </div>
      </div>
    </div>
  </Container>
</template>
<style scoped>
.action-container {
  width: calc(100% + 8px);
}
</style>
